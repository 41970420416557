<template>
  <f7-page>
  
	<f7-navbar>
		<f7-nav-title><i class="f7-icons">play_rectangle</i> Video</f7-nav-title>
		<f7-nav-right>
			<f7-link back icon-f7="arrowshape_turn_up_left"></f7-link>
		</f7-nav-right>
	</f7-navbar>
	
	<f7-card v-for="(v,index) in video" :key="index" style="box-shadow: 5px 10px 18px #888888;">
	<f7-card-content>
	<div style="margin-bottom:5px;"><f7-icon material="play_arrow"></f7-icon> <b>{{v.title}}</b></div>
	<video width="100%" controls>
		  <source :src="v.src" type="video/mp4">
			Your browser does not support the video tag.
		</video> 
	</f7-card-content>
	</f7-card>
	
	
  </f7-page>
</template>
<script>
  export default {
    data() {
      return {
		video:[
		{'title':'Keperluan Atau Kehendak','src':'static/video/video1.mp4','control':false},
		{'title':'Penawaran Dan Permintaan','src':'static/video/video2.mp4','control':false},
		{'title':'Akuantabiliti Dalam Kewangan','src':'static/video/video3.mp4','control':false},
		{'title':'Sedikit-Sedikit Lama-Lama Jadi Bukit','src':'static/video/video4.mp4','control':false},
		],
      }
    },
	methods:{
		play(i){
			this.video[i].control = true;
		}
	}
  }
</script>