<template>
  <f7-page>
  
	<f7-navbar>
		<f7-nav-title><i class="f7-icons">person</i> Profile</f7-nav-title>
		<f7-nav-right>
			<f7-link back icon-f7="arrowshape_turn_up_left"></f7-link>
		</f7-nav-right>
	</f7-navbar>
	<f7-block>
		<div align="center"><f7-icon f7="person_alt_circle" size="100px" color="blue"></f7-icon></div>
		<div align="center">{{user.user_id}}</div>
		<div align="center">{{user.fname}} {{user.lname}}</div>
	</f7-block>
	<f7-block>
	  <f7-row tag="p">
		<f7-col tag="span">
		  <f7-button raised fill round @click="signOut">Sign Out</f7-button>
		</f7-col>
	  </f7-row>
	</f7-block>
	
	<f7-block>
		<f7-list no-hairlines-md>
		  <f7-list-input
			label="First Name"
			type="text"
			:disabled="true"
			:value="form.fname"
		  >
		  </f7-list-input>
		  <f7-list-input
			label="Last Name"
			type="text"
			:disabled="true"
			:value="form.lname"
		  >
		  </f7-list-input>
		  <f7-list-input
			label="Matric ID"
			type="text"
			:disabled="true"
			:value="form.user_id"
		  >
		  </f7-list-input>
		  <f7-list-input
			label="Email"
			type="text"
			:disabled="true"
			:value="form.email"
		  >
		  </f7-list-input>
		</f7-list>
	</f7-block>
	
	<f7-block>
		<f7-row>
			<f7-col><f7-button raised fill round @click="changePass">Change Password</f7-button></f7-col>
		</f7-row>
	</f7-block>
	
	
	 <f7-sheet class="pass-sheet" push>
      <f7-toolbar>
        <div class="left"><f7-link icon-f7="checkmark_alt" @click="savePass"></f7-link></div>
		<div class="center"><b>Change Password</b></div>
        <div class="right"><f7-link sheet-close icon-f7="multiply"></f7-link></div>
      </f7-toolbar>
      <f7-page-content>
        <f7-block>
		<f7-list no-hairlines-md form id="frm_password">
		  <f7-list-input
			label="New Password"
			type="password"
			@input="password.new = $event.target.value"
			required
			validate
		  >
		  </f7-list-input>
		  <f7-list-input
			label="Confirm Password"
			type="password"
			@input="password.confirm = $event.target.value"
			required
			validate
		  >
		  </f7-list-input>
		</f7-list>
	</f7-block>
	
      </f7-page-content>
    </f7-sheet>
	
  </f7-page>
</template>

<script>
  export default {
    data() {
      return {
        user: {},
		form:{},
		password:{},
      };
    },
	mounted(){
		this.load();
	},
    methods: {
      load() {
		var auth = this.$f7.form.getFormData('fl_auth');
		if(auth){
			this.user = JSON.parse(auth);
			this.form = JSON.parse(auth);
		}
      },
	  signOut(){
		const self = this;
		self.$f7.dialog.confirm('Are you sure?','Sign Out',function(){
			self.$f7.form.removeFormData('fl_auth');
			self.$f7.dialog.alert('You have been signed out successfully','Sign Out',function(){
				self.$f7router.back('/');
			});
		});
	  },
	  changePass(){
		const self = this;
		self.$f7.sheet.open('.pass-sheet');
	  },
	  savePass(){
		const self = this;
		if(self.$f7.input.validateInputs('#frm_password')){
			if(self.password.new===self.password.confirm){
				var auth = JSON.parse(self.$f7.form.getFormData('fl_auth'));
				self.$f7.dialog.preloader('Updating..');
				self.$f7.request.post(self.$apiServer+'/auth/update_password',{token:auth.token,password:self.password},function (res) {
					self.$f7.dialog.close();
					if(res.sts==1){
						self.$f7.dialog.alert(res.msg,'Success',function(){
							self.$f7.sheet.close('.pass-sheet');
							self.$f7.form.removeFormData('fl_auth');
							self.$f7router.back('/');
						});
					}else{
						self.$f7.dialog.alert(res.msg,'Alert');
					}
				},function(xhr){
					self.$f7.dialog.close();
					self.$f7.dialog.alert(xhr.statusText,'Alert');
				},'json');
			}else{
				self.$f7.dialog.alert('Password not match','Alert');
			}
		}
	  }
    },
  };
</script>