<template>
  <f7-page>
  
	<f7-navbar>
		<f7-nav-title><i class="f7-icons">lightbulb_slash</i> Forgot Password</f7-nav-title>
		<f7-nav-right>
			<f7-link back icon-f7="arrowshape_turn_up_left"></f7-link>
		</f7-nav-right>
	</f7-navbar>
	<f7-block>
	<f7-block-title>Please enter your email registered with us</f7-block-title>
	<f7-list no-hairlines-md id="frm_forgot">
  <f7-list-input
    outline
    label="Email Address"
    floating-label
    type="text"
    clear-button
	@input="email = $event.target.value"
	required
	validate
  >
  </f7-list-input>
  </f7-list>
		
		<f7-row>
			<f7-col>
			  <f7-button fill round @click="forgot_pass">Submit</f7-button>
			</f7-col>
		</f7-row>
	</f7-block>
	
  </f7-page>
</template>

<script>
  export default {
    data() {
      return {
        email:'',
      };
    },
    methods: {
	  forgot_pass(){
		const self = this;
		if(self.$f7.input.validateInputs('#frm_forgot')){
			self.$f7.dialog.preloader('Please wait..');
			self.$f7.request.post(self.$apiServer+'/auth/forgot',{forgot_email:self.email},function (res) {
				self.$f7.dialog.close();
				if(res.sts==1){
					self.$f7.dialog.alert(res.msg,'Success',function(){
						self.$f7router.back('/');
					});
				}else{
					self.$f7.dialog.alert(res.msg,'Alert');
				}
			},function(xhr){
				self.$f7.dialog.close();
				self.$f7.dialog.alert(xhr.statusText,'Alert');
			},'json');
		}
	  },
    },
  };
</script>