<template>
  <f7-page>
  
	<f7-navbar>
		<f7-nav-title><i class="f7-icons">money_dollar_circle</i> Calculator</f7-nav-title>
		<f7-nav-right>
			<f7-link back icon-f7="arrowshape_turn_up_left"></f7-link>
		</f7-nav-right>
	</f7-navbar>
	<f7-block>
		<f7-list inline-labels no-hairlines-md>
		  <f7-list-input
			label="Income"
			type="text"
			placeholder="Enter monthly income"
			clear-button
			:value="income"
			@input="income = $event.target.value"
		  >
	  </f7-list-input>
	  </f7-list>
	</f7-block>

	<f7-block-title>List of Expenses</f7-block-title>
	<f7-block>
	<div class="expenses" v-for="e in expenses">
		<f7-row>
			<f7-col align="left" width="45" class="title"><b>{{e.item}}</b></f7-col>
			<f7-col align="center" width="10">({{e.percent}}%)</f7-col>
			<f7-col align="right" width="45" class="money">{{calculate(e.percent)}}</f7-col>
		</f7-row>
	</div>
	</f7-block>


  </f7-page>
</template>

<script>
  export default {
    data() {
      return {
		expenses:[
			{'item':'Saving','percent':20},
			{'item':'Food','percent':35},
			{'item':'Book and Stationary','percent':10},
			{'item':'Bus fare / Petrol','percent':10},
			{'item':'Handphone Prepaid','percent':15},
			{'item':'Donation','percent':2},
			{'item':'Contigency','percent':5},
			{'item':'Enterteinment','percent':3},
		],
		income:null,
      }
    },
	methods:{
		calculate(percent){
			if(Number(this.income)){
				var total = percent*this.income/100;
				return 'RM '+total.toFixed(2);
			}
			return 'RM 0';
		}
	}
  }
</script>