
import Home from '../pages/home.vue';
import Note from '../pages/note.vue';
import Video from '../pages/video.vue';

import Quiz from '../pages/quiz.vue';
import Quiz_Question from '../pages/quiz_question.vue';

import Forum from '../pages/forum.vue';
import Forum_Detail from '../pages/forum_detail.vue';

import Calculator from '../pages/calc.vue';
import Profile from '../pages/profile.vue';
import Login from '../pages/login.vue';
import Register from '../pages/register.vue';
import Forgot from '../pages/forgot.vue';
import Qrcode from '../pages/qrcode.vue';
import NotFoundPage from '../pages/404.vue';

var routes = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/note/',
    component: Note,
	beforeEnter : checkAuth,
  },
  {
    path: '/video/',
    component: Video,
	beforeEnter : checkAuth,
  },
  {
    path: '/quiz/',
    component: Quiz,
	beforeEnter : checkAuth,
	routes: [
		{
		  path: 'question/:id/',
          component: Quiz_Question,
		  beforeEnter : checkAuth,
		},
	],
  },
  {
    path: '/forum/',
    component: Forum,
	beforeEnter : checkAuth,
	routes: [
		{
		  path: 'detail/:id/',
          component: Forum_Detail,
		  beforeEnter : checkAuth,
		},
	],
  },
  {
    path: '/calc/',
    component: Calculator,
	beforeEnter : checkAuth,
  },
  {
    path: '/profile/',
    component: Profile,
	beforeEnter : checkAuth,
  },
  {
    path: '/qrcode/',
    component: Qrcode,
	beforeEnter : checkAuth,
  },
  {
    path: '/login/',
    component: Login,
  },
  {
    path: '/register/',
    component: Register,
  },
  {
    path: '/forgot/',
    component: Forgot,
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;

function checkAuth(routeTo, routeFrom, resolve, reject){
	var auth = this.app.form.getFormData("fl_auth");
	if(auth){
		auth = JSON.parse(auth);
		if(auth.isLoggedIn=='true'){
			resolve();
		}else{
			reject();
			this.app.views.main.router.navigate('/login/',{props:{routeTo:routeTo.path}});
		}
	}else{
		reject();
		this.app.views.main.router.navigate('/login/',{props:{routeTo:routeTo.path}});
	}
}