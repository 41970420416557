<template>
  <f7-page>
  
	<f7-navbar>
		<f7-nav-title><i class="f7-icons">doc_text</i> Note</f7-nav-title>
		<f7-nav-right>
			<f7-link back icon-f7="arrowshape_turn_up_left"></f7-link>
		</f7-nav-right>
	</f7-navbar>
	
	<f7-block-title>List of Note</f7-block-title>
	<f7-list>
	  <f7-list-item v-for="(n,index) in note" :key="index" :title="'Bab '+n.bab+' : '+n.title" link="#" @click="openPopup(index)"></f7-list-item>
	</f7-list>
	
	
	 <f7-popup class="demo-popup" :opened="popupOpened" @popup:closed="closePopup">
      <f7-page>
        <f7-navbar>
		<f7-nav-title><i class="f7-icons">doc_text</i> {{title}}</f7-nav-title>
          <f7-nav-right>
			<f7-link popup-close icon-f7="multiply"></f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
			<pdf :src="src" :page="pageNum" @num-pages="pageCount = $event" @page-loaded="loaded" style="box-shadow: 5px 10px 18px #888888;"></pdf>
			<br>
			<br>
		</f7-block>
		<f7-toolbar position="bottom">
		  <f7-link @click="prev"><f7-icon material="navigate_before"></f7-icon> Prev</f7-link>
		  <div>{{pageNum}} / {{pageCount}}</div>
		  <f7-link @click="next">Next <f7-icon material="navigate_next"></f7-icon></f7-link>
		</f7-toolbar>
      </f7-page>
    </f7-popup>
	
  </f7-page>
</template>

<script>
  export default {
    data() {
      return {
		note:[
		{'bab':'1','title':'Pengenalan Kepada Ekonomi','url':'static/note/bab1_pengenalan_kepada_ekonomi.pdf'},
		{'bab':'2','title':'Keseimbangan Pasaran','url':'static/note/bab2_keseimbangan_pasaran.pdf'},
		{'bab':'3','title':'Wang, Bank dan Pendapatan Individu','url':'static/note/bab3_wang_bank__dan_pendapatan_individu.pdf'},
		{'bab':'4','title':'Pengeluaran','url':'static/note/bab4_pengeluaran.pdf'},
		],
		popupOpened:false,
		title:null,
		src:null,
		pageCount:0,
		pageNum:1,
      }
    },
	methods:{
		openPopup(i){
			var note = this.note[i];
			this.title = note.title;
			this.src = note.url;
			this.pageCount = note.page;
			this.popupOpened = true;
			this.$f7.preloader.show();
		},
		closePopup(){
			this.popupOpened = false;
			this.title = null;
			this.src = null;
			this.pageCount = 0;
			this.pageNum = 1;
		},
		loaded(){
			this.$f7.preloader.hide();
		},
		next(){
			const self = this;
			if(self.pageNum<self.pageCount){
				this.$f7.preloader.show();
				self.pageNum++;
			}else{
			
			}
		},
		prev(){
			const self = this;
			if(self.pageNum>1){
				this.$f7.preloader.show();
				self.pageNum--;
			}else{
			
			}
		},
	}
  }
</script>