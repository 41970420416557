<template>
  <f7-page>
  
	<f7-navbar>
		<f7-nav-title><i class="f7-icons">lock_circle</i> Register</f7-nav-title>
		<f7-nav-right>
			<f7-link back icon-f7="arrowshape_turn_up_left"></f7-link>
		</f7-nav-right>
	</f7-navbar>
	<f7-block>
	<f7-block-title>All field are required.</f7-block-title>
		<f7-list no-hairlines-md form id="frm_register">
			<f7-list-input
			label="First Name"
			floating-label
			type="text"
			placeholder="Your first name"
			clear-button
			required
			validate
			:value="form.fname"
			@input="form.fname = $event.target.value"
		  >
		  </f7-list-input>
		  <f7-list-input
			label="Last Name"
			floating-label
			type="text"
			placeholder="Your last name"
			clear-button
			required
			validate
			:value="form.lname"
			@input="form.lname = $event.target.value"
		  >
		  </f7-list-input>
		  <f7-list-input
			label="Matric ID"
			floating-label
			type="text"
			placeholder="Your Matric ID"
			clear-button
			required
			validate
			:value="form.matric"
			@input="form.matric = $event.target.value"
			pattern="[A-Z][0-9]+"
			minlength=6
			maxlength=12
		  >
		  </f7-list-input>
		  <f7-list-input
			label="Email"
			floating-label
			type="email"
			placeholder="Your Email"
			clear-button
			required
			validate
			:value="form.email"
			@input="form.email = $event.target.value"
		  >
		  </f7-list-input>
		  <f7-list-input
			label="Username"
			floating-label
			type="text"
			placeholder="Your Username"
			clear-button
			required
			validate
			:value="form.username"
			@input="form.username = $event.target.value"
			pattern="[a-zA-Z0-9_@]+" 
			minlength=6
		  >
		  </f7-list-input>
		   <f7-list-input
			label="Password"
			floating-label
			type="password"
			placeholder="Your Password"
			clear-button
			required
			validate
			:value="form.password"
			@input="form.password = $event.target.value"
			pattern="[a-zA-Z0-9!@#$%^&*()_+~`\|/?.>,<]+" 
			minlength=6
			maxlength=12
		  >
		  </f7-list-input>
		  <f7-list-input
			label="Confirm Password"
			floating-label
			type="password"
			placeholder="Your Password"
			clear-button
			required
			validate
			:value="form.password2"
			@input="form.password2 = $event.target.value"
		  >
		  </f7-list-input>
		</f7-list>
		<f7-row>
			<f7-col>
			  <f7-button fill round @click="register">Submit</f7-button>
			</f7-col>
		</f7-row>
	</f7-block>
	
  </f7-page>
</template>

<script>
  export default {
    data() {
      return {
        form: {},
      };
    },
    methods: {
	  register(){
		const self = this;
		if(self.$f7.input.validateInputs('#frm_register')){
			if(self.form.password===self.form.password2){
				self.$f7.dialog.preloader('Registering..');
				self.$f7.request.post(self.$apiServer+'/auth/register',{form:self.form},function (res) {
					self.$f7.dialog.close();
					if(res.sts==1){
						self.$f7.dialog.alert(res.msg,'Success',function(){
							self.$f7router.back('/');
						});
					}else{
						self.$f7.dialog.alert(res.msg,'Alert');
					}
				},function(xhr){
					self.$f7.dialog.close();
					self.$f7.dialog.alert(xhr.statusText,'Alert');
				},'json');
			}else{
				self.$f7.dialog.alert('Password not match','Alert');
			}
		}
	  },
    },
  };
</script>