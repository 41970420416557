<template>
  <f7-page>
  
	<f7-navbar>
		<f7-nav-title><i class="f7-icons">chat_bubble</i> Forum</f7-nav-title>
		<f7-nav-right>
			<f7-link back icon-f7="arrowshape_turn_up_left"></f7-link>
		</f7-nav-right>
	</f7-navbar>
	
	<f7-block-title>List of Forum</f7-block-title>
	<f7-list media-list>
  <f7-list-item swipeout v-for="(f,index) in forum"
	:key="index"
    :link="'/forum/detail/'+f.id+'/'"
    :title="f.title"
    :text="f.descr"
	class="forum-list"
  >
  <div slot="after">
	<f7-icon size="24px" color="green" f7="chat_bubble_2_fill"></f7-icon>
	<span style="font-size:12px;color:green;">{{f.count}}</span>
  </div>
  <f7-swipeout-actions right v-if="auth.category==2">
		<f7-swipeout-button :close="true" color="yellow" @click.prevent="edit(index)"><f7-icon f7="pencil"></f7-icon></f7-swipeout-button>
		<f7-swipeout-button :close="true" color="red" @click.prevent="del(index,$event)"><f7-icon f7="multiply"></f7-icon></f7-swipeout-button>
  </f7-swipeout-actions>
  </f7-list-item>
  </f7-list>
  
  <p v-if="forum.length==0" align="center" style="opacity:0.5">Forum empty</p>
  
	
	<f7-sheet class="add-sheet" style="height:auto;" v-if="auth.category==2">
      <f7-toolbar>
        <div class="left">
			<f7-link icon-f7="checkmark_alt" @click="save"></f7-link>
		</div>
		<div class="center"><b>{{title}}</b></div>
        <div class="right">
          <f7-link sheet-close icon-f7="multiply"></f7-link>
        </div>
      </f7-toolbar>
      <!-- Scrollable sheet content -->
      <f7-page-content>
        <f7-block>
          <f7-list no-hairlines-md form id="frm_forum">
			  <f7-list-input
				label="Title"
				type="text"
				clear-button
				maxlength="100"
				:value="form.title"
				@input="form.title = $event.target.value"
				required
				validate
			  >
			  </f7-list-input>
			   <f7-list-input
				label="Description"
				type="textarea"
				clear-button
				maxlength="500"
				:value="form.descr"
				@input="form.descr = $event.target.value"
				required
				validate
			  >
			  </f7-list-input>
			</f7-list>
        </f7-block>
      </f7-page-content>
    </f7-sheet>
	
	<f7-fab position="right-bottom" slot="fixed" color="blue" @click="add" v-if="auth.category==2">
		<f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
	</f7-fab>
	
  </f7-page>
</template>

<script>
  export default {
    data() {
      return {
		auth:JSON.parse(this.$f7.form.getFormData('fl_auth')),
		forum:[],
		form:{},
		title:null,
      }
    },
	mounted(){
		this.load();
	},
	methods:{
		load(){
			const self = this;
			self.$f7.preloader.show();
			self.$f7.request.post(self.$apiServer+'/forum/listing',{token:self.auth.token}, function (res) {
				if(res.sts==1){
					self.forum = res.forum;
					self.$nextTick(function(){
						self.$f7.preloader.hide();
					});
				}else{
					self.$f7.preloader.hide();
					self.$f7.dialog.alert(res.msg,'Alert');
				}
			},function(xhr){
				self.$f7.preloader.hide();
				self.$f7.dialog.alert(xhr.statusText,'Alert');
			},'json');
		},
		add(){
			const self = this;
			self.title = 'Add Forum';
			self.form = {};
			self.form.action='add';
			self.$f7.sheet.open('.add-sheet');
		},
		edit(i){
			const self = this;
			self.title = 'Edit Forum';
			var form = self.forum[i];
			self.form = Object.assign({}, form);
			self.form.action='edit';
			self.$f7.sheet.open('.add-sheet');
		},
		save(){
			const self = this;
			if(self.$f7.input.validateInputs('#frm_forum')){
				self.$f7.preloader.show();
				self.$f7.request.post(self.$apiServer+'/forum/add',{token:self.auth.token,form:self.form}, function (res) {
					if(res.sts==1){
						self.forum = res.forum;
						self.$nextTick(function(){
							self.$f7.preloader.hide();
							self.$f7.sheet.close('.add-sheet');
						});
					}else{
						self.$f7.preloader.hide();
						self.$f7.dialog.alert(res.msg,'Alert');
						errors();
					}
				},function(xhr){
					self.$f7.preloader.hide();
					self.$f7.dialog.alert(xhr.statusText,'Alert');
				},'json');
			}
		},
		del(i,e){
			const self = this;
			var forum = self.forum[i];
			self.$f7.dialog.confirm('Are sure to delete this forum?<br><b>'+forum.title+'</b>','Delete Forum',function(){
				self.$f7.preloader.show();
				self.$f7.request.post(self.$apiServer+'/forum/del',{token:self.auth.token,fid:forum.id}, function (res) {
					if(res.sts==1){
						self.forum = res.forum;
						self.$nextTick(function(){
							self.$f7.preloader.hide();
						});
					}else{
						self.$f7.preloader.hide();
						self.$f7.dialog.alert(res.msg,'Alert');
						errors();
					}
				},function(xhr){
					self.$f7.preloader.hide();
					self.$f7.dialog.alert(xhr.statusText,'Alert');
				},'json');
			});
		}
	}
  }
</script>