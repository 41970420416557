<template>
  <f7-page no-swipeback login-screen>
  
	<f7-navbar>
		<f7-nav-title><i class="f7-icons">lock_circle</i> Login</f7-nav-title>
		<f7-nav-right>
			<f7-link back icon-f7="arrowshape_turn_up_left"></f7-link>
		</f7-nav-right>
	</f7-navbar>
	
	<f7-login-screen-title>FINANCIAL LITERACY</f7-login-screen-title>
    <f7-list form id="frm_login">
      <f7-list-input
        label="Username"
        type="text"
        placeholder="Your username"
        :value="username"
        @input="username = $event.target.value"
		required
		validate
		clear-button
      ></f7-list-input>
      <f7-list-input
        label="Password"
        type="password"
        placeholder="Your password"
        :value="password"
        @input="password = $event.target.value"
		required
		validate
		clear-button
      ></f7-list-input>
    </f7-list>
	<f7-block>
	  <f7-row tag="p">
		<f7-col tag="span">
		  <f7-button raised fill round @click="signIn">Sign In</f7-button>
		</f7-col>
	  </f7-row>
	</f7-block>
    <f7-list>
      <f7-list-button raised fill href="/register/">Register</f7-list-button>
      <f7-block-footer>Click register to register as new member.</f7-block-footer>
    </f7-list>
	<f7-list>
      <f7-list-button raised fill href="/forgot/">Forgot Password</f7-list-button>
      <f7-block-footer>Click forgot password to set new password.</f7-block-footer>
    </f7-list>
	
	
  </f7-page>
</template>

<script>
  export default {
    data() {
      return {
        username: '',
        password: '',
      };
    },
    methods: {
      signIn() {
        const self = this;
		if (this.$$('#frm_login')[0].checkValidity()) {
			self.$f7.dialog.preloader('Checking Authorization..');
			self.$f7.request.post(self.$apiServer+'/auth/login',{fl_username:self.username,fl_password:self.password}, function (res) {
				self.$f7.dialog.close();
				if(res.sts==1){
					self.$f7.form.storeFormData('fl_auth',JSON.stringify(res.login));
					self.$f7router.back('/',{force:true});
				}else{
					self.$f7.dialog.alert(res.msg,'Alert');
				}
			},function(xhr){
				self.$f7.dialog.close();
				self.$f7.dialog.alert(xhr.statusText,'Alert');
			},'json');
		}
      },
    },
  };
</script>