<template>
  <f7-page>
  
	<f7-navbar>
		<f7-nav-title><i class="f7-icons">house</i> Home</f7-nav-title>
		<f7-nav-right>
			<f7-link @click="$f7router.navigate('/profile/')" icon-f7="person"></f7-link>
		</f7-nav-right>
	</f7-navbar>
	
	<div class="home_pic">
		<div>FINANCIAL LITERACY</div>
	</div>
	
	<f7-block-title>Menu</f7-block-title>
	<f7-list>
	  <f7-list-item link="/note/" title="Note">
		<f7-icon slot="media" f7="doc_text"></f7-icon>
	  </f7-list-item>
	  <f7-list-item link="/video/" title="Video">
		<f7-icon slot="media" f7="play_rectangle"></f7-icon>
	  </f7-list-item>
	  <f7-list-item link="/quiz/" title="Quiz">
		<f7-icon slot="media" f7="square_pencil"></f7-icon>
	  </f7-list-item>
	  <f7-list-item link="/forum/" title="Forum">
		<f7-icon slot="media" f7="chat_bubble"></f7-icon>
	  </f7-list-item>
	  <f7-list-item link="/calc/" title="Calculator">
		<f7-icon slot="media" f7="money_dollar_circle"></f7-icon>
	  </f7-list-item>
	  <f7-list-item link="/qrcode/" title="QR Code">
		<f7-icon slot="media" f7="qrcode"></f7-icon>
	  </f7-list-item>
	</f7-list>
	
  </f7-page>
</template>

<script>
  export default {
    data() {
      return {

      }
    },
	methods:{

	}
  }
</script>