<template>
  <f7-page>
  
	<f7-navbar>
		<f7-nav-title><i class="f7-icons">chat_bubble_2</i> {{forum.title}}</f7-nav-title>
		<f7-nav-right>
			<f7-link @click="back" icon-f7="arrowshape_turn_up_left"></f7-link>
		</f7-nav-right>
	</f7-navbar>
	
  <f7-list accordion-list style="margin-top:0px;position:fixed;z-index:9;width:100%;">
  <f7-list-item accordion-item title="Detail">
    <f7-accordion-content>
      <f7-block>
        <p>{{forum.descr}}</p>
      </f7-block>
    </f7-accordion-content>
  </f7-list-item>
  </f7-list>
  
  <f7-block style="margin-top:55px;margin-bottom:55px;">
  <f7-messages ref="messages" :scroll-messages-on-edge="true">
	<f7-message style="max-width: 100%;" v-for="(m,index) in message" :first="index==0" :last="index==message.length-1"
	  :key="index"
	  :type="auth.id==m.sender?'sent':'received'"
	  :text="m.post"
	>
	<div slot="content-start">{{auth.id==m.sender?'':m.name}}</div>
	</f7-message>
	</f7-messages>
	<p v-if="message.length==0" align="center" style="opacity:0.5">Message empty</p>
  </f7-block>
  
  <f7-messagebar placeholder="Message (Max 500 characters)" @submit="onSubmit">
  <f7-icon ios="f7:arrow_up_fill" aurora="f7:arrow_up_fill" md="material:send" slot="send-link"></f7-icon>
  </f7-messagebar>
	
	
  </f7-page>
</template>

<script>
  export default {
    data() {
      return {
		id:this.$f7route.params.id,
		forum:{},
		message:[],
		auth:JSON.parse(this.$f7.form.getFormData('fl_auth')),
		timer:null,
		lastid:0,
      }
    },
	mounted(){
		this.load();
	},
	methods:{
		load(){
			const self = this;
			self.$f7.preloader.show();
			self.$f7.request.post(self.$apiServer+'/forum/detail',{token:self.auth.token,id:self.id}, function (res) {
				if(res.sts==1){
					self.forum = res.forum;
					self.message = res.message;
					if(res.message.length>0){
						self.lastid = res.message[res.message.length-1].id;
					}
					self.$nextTick(function(){
						self.$f7.preloader.hide();
						self.check();
					});
				}else{
					self.$f7.preloader.hide();
					//self.$f7.dialog.alert(res.msg,'Alert');
				}
			},function(xhr){
				self.$f7.preloader.hide();
				//self.$f7.dialog.alert(xhr.statusText,'Alert');
			},'json');
		},
		check(){
			const self = this;
			self.$f7.request.post(self.$apiServer+'/forum/getMessage',{token:self.auth.token,id:self.id,lastid:self.lastid}, function (res) {
				if(res.sts==1){
					if(res.message.length>0){
						res.message.forEach(function(value){
							self.message.push(value);
							self.lastid = value.id;
						});
					}
					self.$nextTick(function(){
						self.$f7.preloader.hide();
						sleep();
					});
				}else{
					//self.$f7.dialog.alert(res.msg,'Alert');
					sleep();
				}
			},function(xhr){
				//self.$f7.dialog.alert(xhr.statusText,'Alert');
				sleep();
			},'json');
			
			function sleep(){
				self.timer=setTimeout(function(){ self.check() }, 2000);
			}
		},
		onSubmit(text,clear){
			const self = this;
			clearTimeout(self.timer);
			self.$f7.preloader.show();
			self.$f7.request.post(self.$apiServer+'/forum/sentMessage',{token:self.auth.token,id:self.id,text:text.substr(0,500)}, function (res) {
				if(res.sts==1){
					clear(true);
					self.check();
				}else{
					self.$f7.preloader.hide();
					self.$f7.dialog.alert(res.msg,'Alert');
				}
			},function(xhr){
				self.$f7.preloader.hide();
				self.$f7.dialog.alert(xhr.statusText,'Alert');
			},'json');
			
		},
		back(){
			const self = this;
			clearTimeout(self.timer);
			self.$f7router.back('/forum/',{force:true});
		},
	}
  }
</script>

