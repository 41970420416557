<template>
  <f7-page>
  
	<f7-navbar>
		<f7-nav-title><i class="f7-icons">square_pencil</i> Quiz</f7-nav-title>
		<f7-nav-right>
			<f7-link back icon-f7="arrowshape_turn_up_left"></f7-link>
		</f7-nav-right>
	</f7-navbar>
	
	<f7-block-title>List of Quiz</f7-block-title>
	<f7-list media-list>
		<ul>
			<li v-for="(q,index) in quiz" >
				<a :href="'/quiz/question/'+q.id+'/'" class="item-link item-content">
					<div class="item-inner">
						<div class="item-title-row">
						<div class="item-title">{{q.title}}</div>
					</div>
					<div v-if="q.status" class="item-subtitle">
						<span class="badge color-green">{{q.status}}</span> 
						<span class="badge color-blue">Result : {{q.correct}} / {{q.total}}</span>
					</div>
					</div>
				</a>
			</li>
		</ul>
	</f7-list>
	
  </f7-page>
</template>

<script>
  export default {
    data() {
      return {
		auth:JSON.parse(this.$f7.form.getFormData('fl_auth')),
		quiz:[],
      }
    },
	mounted(){
		this.load();
	},
	methods:{
		load(){
			const self = this;
			self.$f7.preloader.show();
			self.$f7.request.post(self.$apiServer+'/quiz/listing',{token:self.auth.token}, function (res) {
				if(res.sts==1){
					self.quiz = res.quiz;
					self.$nextTick(function(){
						self.$f7.preloader.hide();
					});
				}else{
					self.$f7.preloader.hide();
					self.$f7.dialog.alert(res.msg,'Alert');
				}
			},function(xhr){
				self.$f7.preloader.hide();
				self.$f7.dialog.alert(xhr.statusText,'Alert');
			},'json');
		}
	}
  }
</script>