<template>
  <f7-page>
  
	<f7-navbar>
		<f7-nav-title><i class="f7-icons">square_pencil</i> Question</f7-nav-title>
		<f7-nav-right>
			<f7-link v-if="quiz.status!='SUBMITTED'" icon-material="done_all" @click="submit"></f7-link>
			<f7-link back icon-f7="arrowshape_turn_up_left"></f7-link>
		</f7-nav-right>
	</f7-navbar>

	<f7-card outline>
		<f7-card-content>
			<div align="center"><b>{{quiz.title}}</b></div>
			<div align="center">Soalan {{question_no}}</div>
		</f7-card-content>
	</f7-card>
	
	<f7-card outline>
		<f7-card-content v-html="question.desc">
		
		</f7-card-content>
	</f7-card>
	
	<f7-card outline>
		<f7-card-content>
			<div class="list">
			  <ul>
				<li v-for="a in answer">
				  <label class="item-radio item-radio-icon-start item-content">
					<input type="radio" name="question_answer" :checked="answer_choose.id==a.id" @change="save_answer(a.id)" :disabled="answer_choose.submit==1"/>

					<f7-row no-gap style="width:50px;margin-left:0px;">
						<f7-col><i class="icon icon-radio"></i></f7-col>
						<f7-col><b>{{a.code}}</b></f7-col>
					</f7-row>
					
					<div class="item-inner">
					  <div class="item-title" style="font-size:small;white-space: initial;">{{a.desc}}</div>
					</div>
				  </label>
				</li>
			   </ul>
			</div>
		</f7-card-content>
	</f7-card>
	
	<f7-toolbar position="bottom">
	  <f7-link @click="prev"><f7-icon material="navigate_before"></f7-icon> Prev</f7-link>
	  <div>{{question_no}} / {{question_count}}</div>
	  <f7-link @click="next">Next <f7-icon material="navigate_next"></f7-icon></f7-link>
	</f7-toolbar>
	<br>
	<br>
	<br>
	
	
	<f7-popup class="start-quiz-popup">
      <f7-page>
        <f7-navbar title="Start Quiz">
          <f7-nav-right>
            <f7-link popup-close icon-f7="multiply"></f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
          
        </f7-block>
      </f7-page>
    </f7-popup>
	
	
  </f7-page>
</template>

<script>
  export default {
    data() {
      return {
		id:this.$f7route.params.id,
		quiz:{status:'SUBMITTED'},
		question:{},
		question_count:0,
		question_no:1,
		answer:[],
		answer_choose:{},
		auth:JSON.parse(this.$f7.form.getFormData('fl_auth')),
      }
    },
	mounted(){
		this.load();
	},
	methods:{
		load(){
			const self = this;
			self.$f7.preloader.show();
			self.$f7.request.post(self.$apiServer+'/quiz/detail',{token:self.auth.token,id:self.id,question_no:self.question_no}, function (res) {
				if(res.sts==1){
					self.quiz = res.quiz;
					self.question_count = res.question_count;
					self.question = res.question;
					self.answer = res.answer;
					self.answer_choose = {};
					if(res.choose != null){
						self.answer_choose = res.choose;
					}
					self.$nextTick(function(){
						self.$f7.preloader.hide();
					});
				}else{
					self.$f7.preloader.hide();
					self.$f7.dialog.alert(res.msg,'Alert');
				}
			},function(xhr){
				self.$f7.preloader.hide();
				self.$f7.dialog.alert(xhr.statusText,'Alert');
			},'json');
		},
		next(){
			const self = this;
			if(self.question_no<self.question_count){
				self.question_no++;
				self.get_question();
			}else if(self.quiz.status!='SUBMITTED'){
				self.submit();
			}
		},
		prev(){
			const self = this;
			if(self.question_no>1){
				self.question_no--;
				self.get_question();
			}else{
			
			}
		},
		get_question(){
			const self = this;
			self.$f7.preloader.show();
			self.$f7.request.post(self.$apiServer+'/quiz/question',{token:self.auth.token,id:self.id,question_no:self.question_no}, function (res) {
				if(res.sts==1){
					self.question = res.question;
					self.answer = res.answer;
					self.answer_choose = {};
					if(res.choose != null){
						self.answer_choose = res.choose;
					}
					self.$nextTick(function(){
						self.$f7.preloader.hide();
					});
				}else{
					self.$f7.preloader.hide();
					self.$f7.dialog.alert(res.msg,'Alert');
				}
			},function(xhr){
				self.$f7.preloader.hide();
				self.$f7.dialog.alert(xhr.statusText,'Alert');
			},'json');
		},
		save_answer(aid){
			const self = this;
			self.answer_choose.id = aid;
			if(aid!=null){
				self.$f7.preloader.show();
				self.$f7.request.post(self.$apiServer+'/quiz/answer',{token:self.auth.token,quiz_id:self.id,question_id:self.question.id,answer_id:aid}, function (res) {
					if(res.sts==1){
						self.$nextTick(function(){
							self.$f7.preloader.hide();
						});
					}else{
						self.$f7.preloader.hide();
						self.$f7.dialog.alert(res.msg,'Alert');
					}
				},function(xhr){
					self.$f7.preloader.hide();
					self.$f7.dialog.alert(xhr.statusText,'Alert');
				},'json');
			}
		},
		submit(){
			const self = this;
			self.$f7.dialog.confirm('Are you sure?<br><br><font color="red">Please make sure you answer all question.</font>','Submit Quiz', function () {
				self.$f7.preloader.show();
				self.$f7.request.post(self.$apiServer+'/quiz/submit',{token:self.auth.token,quiz_id:self.id}, function (res) {
					if(res.sts==1){
						self.$f7.preloader.hide();
						self.$f7.dialog.alert(res.msg,'Success');
						self.$f7router.back('/quiz/',{force:true});
					}else{
						self.$f7.preloader.hide();
						self.$f7.dialog.alert(res.msg,'Alert');
					}
				},function(xhr){
					self.$f7.preloader.hide();
					self.$f7.dialog.alert(xhr.statusText,'Alert');
				},'json');
			});
		},
	}
  }
</script>